import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './pages/HomeScreen';
import LessonsScreen from './pages/LessonsScreen';
import LessonPlanScreen from './pages/LessonPlanScreen';
import TutorialScreen from './pages/TutorialScreen';
import PresentScreen from './pages/PresentScreen';
import GameScreen from './pages/GameScreen';
import GamesDisplayScreen from './pages/GamesDisplayScreen';
import CanvaPresentationScreen from './pages/CanvaPresentationScreen'; // Import the new component
import './App.css';
import NavBar from './components/NavBar';
import Demo from "./components/DialogModal/Demo";

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar/>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/lessons" element={<LessonsScreen />} />
          <Route path="/lessons/:id" element={<LessonPlanScreen />} />
          {/* New Canva embed route */}
          <Route path="/lessons/:id/canva" element={<CanvaPresentationScreen />} />
          <Route path="/tutorial" element={<TutorialScreen />} />
          <Route path="/games" element={<GamesDisplayScreen />} />
          <Route
            path="/lessons/:id/present/:sectionName"
            element={<PresentScreen />}
          />
          <Route
            path="/game/:gameName"
            element={<GameScreen />}
          />
          <Route
            path='lesson-2-demo'
            element={<Demo />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
