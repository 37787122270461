import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import '../styles/CanvaPresentationScreen.css';

function CanvaPresentationScreen() {
  const { id } = useParams();
  const navigate = useNavigate();

  // Mapping of lesson IDs to their published Canva website URLs
  const canvaMapping = {
    '1': 'https://rhythm1-overturegames-org.my.canva.site/',
    '2': 'https://rhythm1-overturegames-org.my.canva.site/lesson-2-rhythm-2',
    '3': 'https://rhythm1-overturegames-org.my.canva.site/lesson-3-harmony-1'
  };

  // Mapping of lesson IDs to their Google Document embed URLs
  const googleDocMapping = {
    '1': 'https://docs.google.com/document/d/e/2PACX-1vQ_L25O7LQQ27VUezSarEeUigr0eNz0xzFnYap_ULDtMUm8uDmnJ70_dPQw2g_0XDOym7EadjmeHJKj/pub?embedded=true',
    '2': 'https://docs.google.com/document/d/e/2PACX-1vT_pDSslN4G6VlCyG8NMlKDy581HflJrhgP6LypyM11wX6V_iwwReKUEqqmJjy1TFIpcL0RKolmOVoN/pub?embedded=true',
    '3': 'https://docs.google.com/document/d/e/2PACX-1vR0vi4m9Q2S8_aQmn3YmORM6i3-YDSbWu4KC-Wrr8bnMjUUAQEPAdniY1QSl9DIU2KdjMxpMJ6t941E/pub?embedded=true',
  };

  const canvaUrl = canvaMapping[id];
  const googleDocUrl = googleDocMapping[id];

  const openPresentation = () => {
    if (canvaUrl) {
      window.open(canvaUrl, '_blank');
    }
  };

  return (
    <div className="canva-screen">
      {googleDocUrl ? (
        <iframe
          src={googleDocUrl}
          title={`Lesson ${id} Document`}
          className="embed-iframe"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : (
        <p className="message">
          Sorry, no Google Doc embed is configured for lesson {id}.
        </p>
      )}

      <div className="button-container">
        <button className="shared-button" onClick={() => navigate('/lessons')}>
          <ArrowLeft size={24} />
          <span>Back to Lessons</span>
        </button>

        <button className="shared-button" onClick={openPresentation}>
          <span>Open Presentation</span>
          <ArrowRight size={24} />
        </button>
      </div>
    </div>
  );
}

export default CanvaPresentationScreen;

