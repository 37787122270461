// Place this file in your config directory or suitable location
const gamesConfig = {
  'BeatDecoders': {
    buildFolder: 'WebGLBuilds/BeatDecoders/Build',
    companyName: 'MikeBraverman',
    productVersion: '1.0',
    image: "/images/RhythmDecoders.png",
    description: "Learn to make a beat | Discover the 1st element: RHYTHM.",
    displayTitle: "Beat Decoders V1"
  },
  'RocketRace': {
    buildFolder: 'WebGLBuilds/RocketRace/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/rocketrace.png",
    description: "Compete as a team to learn INSTRUMENTS and HARMONY.",
    displayTitle: "Rocket Race"
  },
  "Songly": {
    buildFolder: "WebGLBuilds/Songly/Build",
    companyName: "Overture Games",
    productVersion: "1.1",
    image: "/images/Songly.png",
    description: "Craft a structured MELODY!",
    displayTitle: "Songly: Melodic Form"
  },
    "GravityGame": {
    buildFolder: "WebGLBuilds/GravityGame/Build",
    companyName: "Overture Games",
    productVersion: "1.1",
    image: "/images/gravity_game.png",
    description: "A fun brain break!",
    displayTitle: "Gravity Game"
  },
  "InstrumentGuesser1": {
    buildFolder: "WebGLBuilds/InstrumentGuesser1/Build",
    companyName: "Overture Games",
    productVersion: "1.0",
    image: "/images/instrument_guesser.png",
    description: "Work together to guess INSTRUMENTS by listening.",
    displayTitle: "Guess the Instrument!"
  },
  'HarmonyGuesser': {
    buildFolder: 'WebGLBuilds/HarmonyGuesser/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/harmony_guesser.png",
    description: "Learn the difference between major and minor chords.",
    displayTitle: "Harmony Guesser: Major or Minor?"
  },
  'RhythmRally': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/rhythm_rally.png",
    description: "Compete and improve your RHYTHM memory.",
    displayTitle: "Rhythm Rally"
  },
  'BeatDecoders2.0': {
    buildFolder: 'WebGLBuilds/BeatDecoders2.0/Build',
    companyName: 'MikeBraverman',
    productVersion: '1.0',
    image: "/images/BeatDecodersV2.png",
    description: "Learn to make a beat | Discover the 1st element: RHYTHM.",
    displayTitle: "Beat Decoders V2: In Development"
  }
  };
  
  export default gamesConfig;