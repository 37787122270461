// LessonsScreen.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Music, Zap, Star, Sun, Moon, Cloud, Heart, Smile } from 'lucide-react';
import '../styles/LessonsScreen.css'; // Importing the custom CSS file
import lessonScreenData from '../assets/lessonScreenData.json'; // Assuming JSON is in assets folder

const LessonsScreen = () => {
  const [lessons, setLessons] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Directly setting lessons from the imported JSON file
    setLessons(lessonScreenData.lessonScreenData);
  }, []);

  const iconMap = [Zap, Music, Star, Sun, Moon, Cloud, Heart, Smile];

  const lessonNumberOffset = 1; // Offset lesson numbers due to the tutorial card

  return (
    <div className="lessons-screen">
      <div className="lessons-card">
        <header className="lessons-header">
          <h1 className="lessons-title">Lessons</h1>
          <p className="lessons-subtitle">Explore our interactive lessons!</p>
        </header>

        <main>
          {/* Download Full Curriculum Button */}
          <div className="tutorial-container">
            <a
              href="/FullCurriculum.pdf"
              download
              className="tutorial-button"
            >
              Download Full Curriculum PDF
            </a>
          </div>

          <ul className="lessons-list">
            {/* Tutorial Card */}
            <li className="lesson-item">
              <div className="lesson-info">
                <div className="lesson-icon">
                  {/* Using the first icon from iconMap */}
                  <Zap size={24} className="icon" />
                </div>
                <div>
                  <h3 className="lesson-title">Tutorial: Begin Here!</h3>
                </div>
              </div>
              <button
                className="lesson-button"
                onClick={() => navigate('/tutorial')}
              >
                Start
              </button>
            </li>

            {/* Mapping over only the first 3 lessons */}
            {lessons.slice(0, 3).map((lesson, index) => {
              // Adjust icon index to prevent overlapping with the tutorial icon
              const IconComponent = iconMap[(index + 1) % iconMap.length];

              // Calculate the lesson number
              const lessonNumber = index + lessonNumberOffset;

              // Determine the navigation path and state
              let navigationPath = `/lessons/${lesson._id}/canva`;
              let navigationState = { lessonNumber };

              return (
                <li
                  key={lesson._id}
                  className={`lesson-item ${lesson.mandatory ? 'mandatory' : ''}`}
                >
                  <div className="lesson-info">
                    <div className="lesson-icon">
                      <IconComponent size={24} className="icon" />
                    </div>
                    <div>
                      <h3 className="lesson-title">
                        Lesson {lessonNumber}: {lesson.title}
                      </h3>
                    </div>
                  </div>
                  <button
                    className="lesson-button"
                    onClick={() =>
                      navigate(navigationPath, {
                        state: navigationState,
                      })
                    }
                  >
                    {lesson.completed ? 'Play Again' : 'Start'}
                  </button>
                </li>
              );
            })}
          </ul>
        </main>
      </div>
    </div>
  );
};

export default LessonsScreen;
